import React from 'react';

import * as styles from './index.module.scss'

interface ContainerProps {
  children: React.ReactNode
}

export const Container = ({
  children,
  ...props
}: ContainerProps) => {
  return (
    <div className={styles.container} {...props}>{children}</div>
  )
}

export default Container