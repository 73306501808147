import React, { ReactElement, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import SEO from '@Components/SEO';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IPageProps, IPageData, IBlock } from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';
import Text from '@Src/components/Text';
import moment from 'moment';
import Container from '@Src/components/sitestructure/Container';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeArticle: IPageData;
	};
}

const ArticlePage = ({ data, location, transitionStatus, pageContext }: Props): ReactElement => {
	const { title, seoSettings, theme, content, published = null, summary = null, image = null } = data.contentfulPageTypeArticle;
	const { setTheme: setPageTheme } = usePageTheme();
	const { pageType, setPageType, setPageTitle, mode } = useApp();
  
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme('light');
			setPageTitle(title);
			setPageType(pageContext.type);
		}

		console.log('STANDARD:', transitionStatus);
	}, [transitionStatus]);

	return (
		<ContentLayout transitionState={localTransStatus}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/> 
        <div style={{padding: 0}}>
            <img style={{padding: 0, margin: 0}} src={image?.url ?? `/assets/svg/ALOADED_Placeholder_Image.svg`} style={{width: '100%', aspectRatio: '16/9'}} />
      
          <Container>
            <article style={{margin: 13}}>

              <Text theme={'light'} heading01={title} />
              <Text theme={'light'} htmlText={summary} />
              <div style={{opacity: 0.5}}>
                  <Text theme={mode} htmlText={`Published: ${published ? moment(published).fromNow() : 'Unknown date'}`} />
              </div>
              {content &&
                content.map(
                  (module: IBlock, index: number): ReactElement => (
                    <ContentfulModule key={module.id} type={module.type} data={module} />
                  )
                )}
            </article>
          </Container> 
        </div>
		  </ContentLayout>
	);
};

export default ArticlePage;

export const query = graphql`
	query ($id: String!) {
		contentfulPageTypeArticle(id: { eq: $id }) {
      image {
        url
      }
      published
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;
